import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import homePage from "./components/homePage";
import infoPage from "./components/infoPage";
import Footer from "./components/footer";
import error from "./components/error";
import va2003 from "./components/bookVA2003";
import va2009 from "./components/bookVA2009";
import va2012 from "./components/bookVA2012";
import va2012mv from "./components/bookVA2012mv";
import va2014 from "./components/bookVA2014";
import va2015 from "./components/bookVA2015";
import va2016 from "./components/bookVA2016";
import va2017 from "./components/bookVA2017";
import va2018 from "./components/bookVA2018";
import va2019 from "./components/bookVA2019";
import va2021 from "./components/bookVA2021";
import va2022 from "./components/bookVA2022";
import poland from "./components/bookPuola";
import icPoland from "./components/bookIcPuola";
import instacrap2014 from "./components/bookInstacrap2014";
import instacrap2015 from "./components/bookInstacrap2015";
import instacrap2016 from "./components/bookInstacrap2016";
import instacrapNA1 from "./components/bookInstacrapNA1";

function App() {
  return (
    <div id="wrapper">
      <BrowserRouter>
        <main>
          <Switch>
            <Route exact path="/" component={homePage} />
            <Route path="/info" component={infoPage} />
            <Route path="/va2003" component={va2003} />
            <Route path="/va2009" component={va2009} />
            <Route path="/va2012" component={va2012} />
            <Route path="/va2012mv" component={va2012mv} />
            <Route path="/va2014" component={va2014} />
            <Route path="/va2015" component={va2015} />
            <Route path="/va2016" component={va2016} />
            <Route path="/va2017" component={va2017} />
            <Route path="/va2018" component={va2018} />
            <Route path="/va2019" component={va2019} />
            <Route path="/va2021" component={va2021} />
            <Route path="/va2022" component={va2022} />
            <Route path="/poland" component={poland} />
            <Route path="/instacrap-poland" component={icPoland} />
            <Route path="/instacrap2014" component={instacrap2014} />
            <Route path="/instacrap2015" component={instacrap2015} />
            <Route path="/instacrap2016" component={instacrap2016} />
            <Route path="/instacrapNA1" component={instacrapNA1} />
            <Route path="*" component={error} />
          </Switch>
        </main>
      </BrowserRouter>
      <Footer />
    </div>
  );
}
export default App;
