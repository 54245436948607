import React from "react";
import '../style/style.css';
import {Helmet} from "react-helmet";
import Navbar from "./navbar";
import himmleri from "../images/cat.jpg";
import myself from "../images/omakuva.png";
import BookCollections from "./bookCollections";

class homepage extends React.Component {
    render () {
        return (
            <div id="page">

                <Helmet>
                    <title>Valontuoja - Info</title>
                    <meta property="og:image" content="../images/thumbnail.jpg" />
                </Helmet>

                <Navbar />

                <div id="info" className="container">

                    <h1 className="mt-4 mb-3">Welcome to Valontuoja</h1>

                    <p>Valontuoja is a website where I, Jani, publish my digital photo collections.
                        File sizes can be large, so it may take a while to load the previews.</p>

                    <h4 className="mt-4 mb-3">About me</h4>

                    <img src={myself} alt="Jani of Finland" title="Whee!" className="img-fluid rotate" style={
                        { width:160,  float:'right', marginLeft:30, marginBottom:20} } />

                    <p>I'm <abbr title="I'm one of Tom's men">Jani of Finland.</abbr> I work as a full-time web developer.
                        Before that, I have worked as a graphic designer. </p>

                    <p>In my work and free time, documenting has become an everyday routine.
                        I learn by taking notes and I self-reflect by taking photos.
                        Photographing is the one thing that I never want to do for work: It would ruin it as a hobby.</p>

                    <p>I started photographing in 2002 and I've been taking photos every day since.
                        As a photographer I don't want to restrict myself into one category.
                        I don't care about the comments either, I just like to do what I do and the way I do it.</p>

                     <p>You can find my full photo stream at <a href="https://www.flickr.com/photos/dommend/">flickr.com/photos/dommend</a>,
                        and my other side project "paikkatietokanta" (location database) can be found <a href="http://paikkatietokanta.net/">here.</a></p>

                    <p>Also here's a photo of my cat.</p>

                    <img src={himmleri} alt="Cat" style={{width:200}} className="img-fluid" />

                </div>

                <div id="collections-wrapper">
                    <BookCollections />
                </div>

            </div>
        );
    }
}
export default homepage;