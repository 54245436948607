import React from "react";
import BookCollections from "./bookCollections";
import '../style/style.css';
import logo from "../images/valontuoja_logo.svg";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

class homepage extends React.Component {
    render () {
        return (
            <div id="page">

                <Helmet>
                    <title>Valontuoja</title>
                    <meta property="og:image" content="../images/thumbnail.jpg" />
                </Helmet>

                <h1 className="logo">
                    <img src={logo} alt={"Valontuoja"} />
                </h1>

                <div id="welcome">
                    <p>Valontuoja is a website where I, Jani, publish my digital photo collections. <br />
                        File sizes can be large, so it may take a while to load the previews.</p>

                    <p><NavLink to="/info" className="btn btn-secondary mt-3">
                        Would you like to know more?
                    </NavLink></p>
                </div>
                <div id="collections-wrapper">
                <BookCollections />
                </div>
            </div>
        );
    }
}
export default homepage;