import React, { useState } from 'react';

import { SizeMe } from 'react-sizeme'
import { Document, Page, pdfjs } from 'react-pdf';
import Navbar from './navbar';
import BookCollections from "./bookCollections";
import {Helmet} from "react-helmet";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Book = () => {
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const bookURL = "//valontuoja.fi/kirjat/Valontuoja_2017/valontuoja_2017_web_small.pdf";

    return (
        <div>

            <Helmet>
                <title>Valontuoja - Valontuoja 2017</title>
                <meta name="description" content="Valontuoja 2017" />
                <meta property="og:image" content="../images/va2017.jpg" />
            </Helmet>

            <Navbar bookURL={bookURL} />

            <div id="book">

                <div id="details" className="container">
                    <h2>Valontuoja 2017</h2>
                    <div className="meta">
                        File size: 38M | Pages: {numPages || 'Loading...'}
                    </div>

                    <div className="description">

                    </div>
                </div>


                <SizeMe
                    render={({ size }) => (
                        <Document
                            file={bookURL}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={ <div className="spinner-border text-secondary" role="status"></div> }
                        >
                            {
                                Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                            <Page
                                                width={size.width ? size.width : 1}
                                                height={size.height ? size.height : 1}
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                            />
                                    ),
                                )
                            }
                        </Document>)}
                />
            </div>
            <div id="collections-wrapper">
                <BookCollections />
            </div>
        </div>
    );
};
export default Book;