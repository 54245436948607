import { NavLink } from "react-router-dom";
import React from "react";
import polandimg from "../images/poland.jpg";
import icPoland from "../images/icpoland.jpg";

import va2003img from "../images/va2003.jpg";
import va2009img from "../images/va2009.jpg";
import va2012img from "../images/va2012.jpg";
import va2012mvimg from "../images/va2012mv.jpg";
import va2014img from "../images/va2014.jpg";
import va2015img from "../images/va2015.jpg";
import va2016img from "../images/va2016.jpg";
import va2017img from "../images/va2017.jpg";
import va2018img from "../images/va2018.jpg";
import va2019img from "../images/va2019.jpg";
import va2021img from "../images/va2021.jpg";
import va2022img from "../images/va2022.jpg";

import ic2014 from "../images/instacrap2014.jpg";
import ic2015 from "../images/instacrap2015.jpg";
import ic2016 from "../images/instacrap2016.jpg";
import icNA1 from "../images/instacrapNA1.jpg";

class bookCollections extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container BookCollections">
        <h1 id="collections-title">Collections</h1>

        <h2>Poland</h2>
        <div className="row image-grid">
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/poland"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={polandimg}
                className="img-responsive center-block"
                alt="Random sceneries from Poland"
              />
              <h5>
                <strong>Random sceneries from Poland (2019)</strong> (68M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/instacrap-poland"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={icPoland}
                className="img-responsive center-block"
                alt="Instacrap: Poland"
              />
              <h5>
                <strong>
                  Instacrap: Poland - A Collection of Vacation Photos 2018 &#38;
                  2019 (2021)
                </strong>{" "}
                (24M)
              </h5>
            </NavLink>
          </div>
        </div>

        <h2 className="mt-5">Valontuoja</h2>

        <div className="row image-grid">
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2022"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2022img}
                className="img-responsive center-block"
                alt="Valontuoja 2022"
              />
              <h5>
                <strong>Valontuoja 2022</strong> (21M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2021"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2021img}
                className="img-responsive center-block"
                alt="Valontuoja 2021"
              />
              <h5>
                <strong>Valontuoja 2021</strong> (34M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2019"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2019img}
                className="img-responsive center-block"
                alt="Valontuoja 2019-2020"
              />
              <h5>
                <strong>Valontuoja 2019-2020</strong> (28M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2018"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2018img}
                className="img-responsive center-block"
                alt="Valontuoja 2018"
              />
              <h5>
                <strong>Valontuoja 2018</strong> (13M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2017"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2017img}
                className="img-responsive center-block"
                alt="Valontuoja 2017"
              />
              <h5>
                <strong>Valontuoja 2017</strong> (38M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2016"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2016img}
                className="img-responsive center-block"
                alt="Valontuoja 2016"
              />
              <h5>
                <strong>Valontuoja 2016</strong> (38M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2015"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2015img}
                className="img-responsive center-block"
                alt="Valontuoja 2015"
              />
              <h5>
                <strong>Valontuoja 2015</strong> (36M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2014"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2014img}
                className="img-responsive center-block"
                alt="Valontuoja 2014"
              />
              <h5>
                <strong>Valontuoja 2014</strong> (31M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2012"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2012img}
                className="img-responsive center-block"
                alt="Valontuoja 2012-2013"
              />
              <h5>
                <strong>Valontuoja 2012-2013</strong> (30M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2012mv"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2012mvimg}
                className="img-responsive center-block"
                alt="Valontuoja 2012-2013 mv"
              />
              <h5>
                <strong>Valontuoja 2012-2013 MV</strong> (19M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2009"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2009img}
                className="img-responsive center-block"
                alt="Valontuoja 2009-2011"
              />
              <h5>
                <strong>Valontuoja 2009-2011</strong> (32M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/va2003"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={va2003img}
                className="img-responsive center-block"
                alt="Valontuoja 2003-2008"
              />
              <h5>
                <strong>Valontuoja 2003-2008</strong> (26M)
              </h5>
            </NavLink>
          </div>
        </div>

        <h2 className="mt-5">Instacrap</h2>

        <div className="row image-grid">
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/instacrapNA1"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={icNA1}
                className="img-responsive center-block"
                alt="Instacrap N/A vol. 1"
              />
              <h5>
                <strong>Instacrap N/A vol. 1 (2020)</strong> (6M)
              </h5>
            </NavLink>
          </div>

          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/instacrap2016"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={ic2016}
                className="img-responsive center-block"
                alt="Instacrap 2016"
              />
              <h5>
                <strong>Instacrap 2016</strong> (16M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/instacrap2015"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={ic2015}
                className="img-responsive center-block"
                alt="Instacrap 2015"
              />
              <h5>
                <strong>Instacrap 2015</strong> (36M)
              </h5>
            </NavLink>
          </div>
          <div className="col-sm-6 col-md-6">
            <NavLink
              to={"/instacrap2014"}
              className="thumbnail"
              activeClassName="active"
            >
              <img
                src={ic2014}
                className="img-responsive center-block"
                alt="Instacrap 2014"
              />
              <h5>
                <strong>Instacrap 2014</strong> (26M)
              </h5>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
export default bookCollections;
