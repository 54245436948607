import React from "react";
import logo from "../images/valontuoja_logo.svg";
import {NavLink} from "react-router-dom";

import { Icon } from 'react-icons-kit'
import {ic_keyboard_arrow_left} from 'react-icons-kit/md/ic_keyboard_arrow_left'
import {ic_file_download} from 'react-icons-kit/md/ic_file_download'


class Navbar extends React.Component {
    render () {
        return (
            <nav className="navbar smart-scroll navbar-light bg-light">

                    <div className="logo col-2">
                        <NavLink to="/" className="navbar-brand">
                            <img src={logo} alt="Valontuoja" />
                        </NavLink>

                    </div>
                    <div className="buttons col-10">
                        <NavLink to="/" className="btn btn-secondary btn-sm">
                            <Icon size={20} icon={ic_keyboard_arrow_left}/>
                            Back to Index
                        </NavLink>

                        {this.props.bookURL
                            ? <a className="btn btn-primary btn-sm"
                                 href={this.props.bookURL} target="_blank" rel="noopener noreferrer" download>
                                <Icon size={20} icon={ic_file_download} />
                                {' '}
                                 Save as PDF
                            </a> :
                            ''
                        }
                    </div>
            </nav>
        );
    }
}
export default Navbar;