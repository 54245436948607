import React from 'react';
import Navbar from './navbar';
import {Helmet} from "react-helmet";

class error extends React.Component {
    render () {
        return (

            <div>
                <Helmet>
                    <title>Valontuoja - 404</title>
                </Helmet>
            <Navbar />
            <div id="notfound">
                    <h1>404</h1>
            </div>
            </div>
        )
    };
}
export default error;